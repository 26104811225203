import React from "react";
import { graphql } from "gatsby";

import TEDxKIContainer from '../components/tedxki-container'
import VideoGallery from '../components/video-gallery'

import * as styles from './videos.module.css'

export default function VideosPage({ data }) {
  const contentfulTEDxKI = data.allContentfulTeDxKi.edges[0].node;
  const groupedVideos = [contentfulTEDxKI.upcomingEvent, ...contentfulTEDxKI.pastEvents]
    .filter((e) => e.relatedVideos)
    .map((e) => {
      return {
        yearIdentifier: e.yearIdentifier,
        relatedVideos: e.relatedVideos
      }
    });
  return (
    <TEDxKIContainer>
      <div className={styles.tedxki_container}>
        <h5>
          All amazing talks from previous events
        </h5>
        {
          groupedVideos.map((gv) => {
            return (
              <div>
                <h3>{gv.yearIdentifier}</h3>
                <VideoGallery videos={gv.relatedVideos}></VideoGallery>
              </div>
            )
          })
        }
      </div>
    </TEDxKIContainer>
  );
};

export const query = graphql`
  query {
    allContentfulTeDxKi {
      edges {
        node {
          upcomingEvent {
            yearIdentifier
            relatedVideos {
              name
              linkToVideo
            }
          }
          pastEvents {
            yearIdentifier
						relatedVideos {
							name
              linkToVideo
            }
          }
        }
      }
    }
  }
`;
